import React from "react";
import styled from "styled-components";
import { TravelPurpose } from "~/applications/Checkout/Domain/TravelPurpose";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import DatesDrawerWithClassicTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/DatesDrawerWithClassicTrigger";
import DestinationDrawerWithClassicTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/DestinationDrawerWithClassicTrigger";
import GuestsDrawerWithClassicTrigger from "~/applications/OfferSearch/Ui/StayRequestForm/GuestsDrawerWithClassicTrigger";
import {
  Guests,
  StayDates,
} from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import { Route } from "~/core/routes";
import { body1 } from "~/guidelines/Typography";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: ${({ theme }) => theme.spacing(2, 0)};

  ${body1};

  background-color: ${({ theme }) => theme.color.background.white};
  box-shadow: ${({ theme }) => theme.shadow.base};
  border-radius: ${({ theme }) => theme.borderRadius.big};
  overflow: hidden;
`;

const Separator = styled.div`
  height: 1px;
  margin: ${({ theme }) => theme.spacing(0, 6)};
  border-top: 1px solid ${({ theme }) => theme.color.line.greySeparator};
`;

export type CardStayRequestFormProps = {
  destination: DestinationMenuEntry | undefined;
  destinations: Array<DestinationMenuEntry>;

  stayDates: StayDates | undefined;
  guests: Guests | undefined;
  travelPurpose: TravelPurpose;

  onDestinationChange: (newDestination: DestinationMenuEntry) => void;
  onStayDatesChange: (newStayDates: StayDates) => void;
  onGuestsChange: (newGuests: Guests) => void;
  onTravelPurposeChange: (newTravelPurpose: TravelPurpose) => void;

  offerMaxCapacity: number | undefined;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
};

const CardStayRequestForm = ({
  destination,
  destinations,

  stayDates,
  guests,
  travelPurpose,

  onDestinationChange,
  onStayDatesChange,
  onGuestsChange,
  onTravelPurposeChange,

  offerMaxCapacity,
  onNewSearch,
}: CardStayRequestFormProps) => {
  return (
    <Card>
      <DestinationDrawerWithClassicTrigger
        destination={destination}
        destinations={destinations}
        onDestinationChange={onDestinationChange}
      />

      <Separator aria-hidden />

      <GuestsDrawerWithClassicTrigger
        guests={guests}
        travelPurpose={travelPurpose}
        onGuestsChange={onGuestsChange}
        onTravelPurposeChange={onTravelPurposeChange}
        offerMaxCapacity={offerMaxCapacity}
        onNewSearch={onNewSearch}
      />

      <Separator aria-hidden />

      <DatesDrawerWithClassicTrigger
        firstAvailableDate={destination?.firstBookableDate ?? null}
        dates={stayDates}
        onDatesChange={onStayDatesChange}
      />
    </Card>
  );
};

export default CardStayRequestForm;
