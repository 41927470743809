import { addYears, isBefore } from "date-fns";
import React, { useMemo } from "react";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import useToday from "~/core/useToday";
import Calendar from "~/guidelines/Calendar";
import { useIsLgScreen } from "~/guidelines/Theme/mediaQueries";

export type DatesSelectorProps = {
  firstAvailableDate: Date | null;
  orientation: "horizontal" | "vertical";
  selectedDate?: { checkIn: Date; checkOut: Date };
  navigationKind: "button" | "scroll";
  onChange?: (newDates: { checkIn: Date; checkOut: Date }) => void;
};

export const DatesSelector = ({
  firstAvailableDate,
  orientation,
  selectedDate,
  navigationKind,
  onChange,
}: DatesSelectorProps) => {
  const today = useToday();

  const firstSelectableDay = useMemo<Date>(() => {
    return firstAvailableDate !== null && isBefore(today, firstAvailableDate)
      ? firstAvailableDate
      : today;
  }, [today, firstAvailableDate]);

  const lastSelectableDay = useMemo<Date>(
    () => addYears(firstSelectableDay, 1),
    [firstSelectableDay],
  );

  const isLgScreen = useIsLgScreen();

  const numberOfVisibleMonths = useMemo(() => {
    if (navigationKind === "button") {
      if (isLgScreen) {
        return 2;
      } else {
        return 1;
      }
    }

    return undefined;
  }, [isLgScreen, navigationKind]);

  return (
    <Selector.Layout>
      <Calendar
        orientation={orientation}
        firstSelectableDay={firstSelectableDay}
        lastSelectableDay={lastSelectableDay}
        numberOfVisibleMonths={numberOfVisibleMonths}
        defaultSelection={
          selectedDate
            ? {
                from: selectedDate.checkIn,
                to: selectedDate.checkOut,
              }
            : undefined
        }
        onSelectedDatesChange={(newSelectedPeriod) => {
          const newDates = {
            checkIn: newSelectedPeriod.from,
            checkOut: newSelectedPeriod.to,
          };

          if (onChange !== undefined) {
            onChange(newDates);
          }
        }}
      />
    </Selector.Layout>
  );
};

export default DatesSelector;
