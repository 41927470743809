import { useQuery } from "@tanstack/react-query";
import { startOfToday } from "date-fns";

/**
 * Using react-query to handle a date is a hack.
 * It allow to have the SWR cache strategy applied on the date value.
 */
const useToday = (): Date => {
  const { data: today } = useQuery(
    ["today"],
    () => Promise.resolve(startOfToday()),
    { staleTime: 60 },
  );

  if (!today) {
    return startOfToday();
  }

  return today;
};

export default useToday;
