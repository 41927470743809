import { PressProps } from "@react-aria/interactions";
import React from "react";
import { usePress } from "react-aria";
import styled, { css } from "styled-components";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import { body1, heading6, heading7 } from "~/guidelines/Typography";

type Layout = "fluid" | "contained";

const ItemContainer = styled.li<{ $isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.color.background.creamDark};

  ${body1};
  text-decoration: none;
  padding: 10px 14px;
  margin: 2px;
  border: 0px solid transparent;
  border-radius: 60px;

  border: none;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? css`
          ${heading6};
          background-color: ${theme.color.brand.blueDark};
          color: ${theme.color.text.white};
          cursor: default;

          ${mediaQueries.md()} {
            ${heading7};
            color: ${theme.color.text.white};
          }
        `
      : css`
          cursor: pointer;
        `}
`;

const PillTabListContainer = styled.nav<{ $layout: Layout }>`
  max-width: ${({ $layout }) => ($layout === "fluid" ? "100%" : "max-content")};
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 2px 2px;
  background-color: ${({ theme }) => theme.color.background.creamDark};
  overflow: none;

  & ${ItemContainer} {
    flex: ${({ $layout }) => ($layout === "fluid" ? "1" : "0")};
  }
`;

const PillTabListScroll = styled.ul`
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px 2px;
  margin: 0;
  gap: 32px;
  border-radius: 40px;

  white-space: nowrap;
  overflow-x: auto;
  --webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export type PillTabListProps = {
  layout: Layout;
  children: React.ReactNode;
};

export const PillTabList = ({
  layout = "contained",
  children,
}: PillTabListProps) => {
  return (
    <PillTabListContainer $layout={layout} role="tablist">
      <PillTabListScroll>{children}</PillTabListScroll>
    </PillTabListContainer>
  );
};

export const PillTab = ({
  active,
  children,
  ...props
}: {
  children: React.ReactNode;
  active: boolean;
} & PressProps) => {
  const { pressProps } = usePress(props);

  return (
    <ItemContainer
      {...pressProps}
      $isSelected={active}
      role="tab"
      aria-selected={active}
    >
      {children}
    </ItemContainer>
  );
};
