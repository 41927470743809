import { useRouter } from "next/compat/router";
import Head from "next/head";
import React from "react";

const PUBLIC_URL = process.env.PUBLIC_URL;

export const PublicPageSeo = () => {
  const router = useRouter();

  if (!router) {
    return null;
  }

  return (
    <Head>
      {router.locales?.map((oneLocale) => {
        const localePathPrefix =
          oneLocale !== router.defaultLocale ? `/${oneLocale}` : "";

        return (
          <link
            key={oneLocale}
            id={`link-alternate-${oneLocale}`}
            rel="alternate"
            hrefLang={oneLocale}
            href={PUBLIC_URL?.concat(localePathPrefix, router.asPath)}
          />
        );
      })}
    </Head>
  );
};
