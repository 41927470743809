import { z } from "zod";
import { AVAILABLE_LOCALES, DEFAULT_LOCALE } from "~/core/locale";

export const localizedFieldsProjection = (locale: AVAILABLE_LOCALES): string =>
  locale;

export const SanityLocalizedFieldSchema = z
  .object({
    [AVAILABLE_LOCALES.en]: z.string().default(""),
    [AVAILABLE_LOCALES.fr]: z.string().default(""),
  })
  .nullable();

// This could be replaced by `z.infer<typeof SanityLocalizedFieldSchema>;` at some point
export type SanityLocalizedField = z.infer<typeof SanityLocalizedFieldSchema>;

export type SanityOptionalLocalizedField =
  | SanityLocalizedField
  | undefined
  | null;

export const localizeField = (
  field: SanityLocalizedField,
  expectedLocale: AVAILABLE_LOCALES,
  defaultLocale: AVAILABLE_LOCALES = DEFAULT_LOCALE,
): string => {
  if (field === undefined || field === null) {
    return "";
  }

  if (
    typeof field[expectedLocale] === "string" &&
    field[expectedLocale] !== ""
  ) {
    return field[expectedLocale] || "";
  }

  return field[defaultLocale] || "";
};

export const localizeOptionalField = (
  field: SanityOptionalLocalizedField,
  expectedLocale: AVAILABLE_LOCALES,
  defaultLocale: AVAILABLE_LOCALES = DEFAULT_LOCALE,
): string | null => {
  if (field === undefined || field === null) {
    return null;
  }

  return localizeField(field, expectedLocale, defaultLocale);
};
