import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const KeyboardArrowDown = ({ color, ...rest }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...rest}
    >
      <path
        d="M8.35302 9L12.233 12.88L16.113 9C16.503 8.61 17.133 8.61 17.523 9C17.913 9.39 17.913 10.02 17.523 10.41L12.933 15C12.543 15.39 11.913 15.39 11.523 15L6.93302 10.41C6.74577 10.2232 6.64053 9.96952 6.64053 9.705C6.64053 9.44049 6.74577 9.18684 6.93302 9C7.32302 8.62 7.96302 8.61 8.35302 9Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default KeyboardArrowDown;
