import { t } from "@lingui/macro";
import {
  addMinutes,
  addMonths,
  differenceInCalendarMonths,
  isSameDay,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { enUS, fr } from "date-fns/locale";
import dynamic from "next/dynamic";
import React, { useCallback, useMemo, useState } from "react";
import type { DateRange, DayModifiers } from "react-day-picker";
import styled, { css } from "styled-components";
import { AVAILABLE_LOCALES } from "~/core/locale";
import { useLocale } from "~/core/useLocale";
import { CenteredPulseLoader } from "~/guidelines/Loaders";
import { body1, heading5, heading7 } from "~/guidelines/Typography";
import KeyboardArrowLeft from "~/guidelines/Icon/Source/KeyboardArrowLeft.svg";
import KeyboardArrowRight from "~/guidelines/Icon/Source/KeyboardArrowRight.svg";

const ReactDayPickerDynamic = dynamic(
  () => import("react-day-picker").then((rdp) => rdp.DayPicker),
  {
    loading: () => <CenteredPulseLoader />,
    ssr: false,
  },
);

const CalendarRoot = styled.div<{
  $showMonthNavigation: boolean;
  $orientation: "horizontal" | "vertical";
}>`
  ${body1};

  & .rdp {
    width: ${({ $orientation }) =>
      $orientation === "horizontal" ? "max-content" : ""};

    & .rdp-months {
      gap: ${({ theme }) => theme.spacing(8)};
      padding-left: 0px !important;
      margin-left: 0px !important;
      justify-content: stretch;
      ${({ $orientation }) =>
        $orientation === "horizontal"
          ? undefined
          : css`
              flex-direction: column;

              & .rdp-table {
                margin: 0 auto;
              }
            `};

      & .rdp-month {
        flex: 1;
        margin: 0;

        &:is(.rdp-caption_start) {
          & .rdp-caption {
            flex-direction: row-reverse;
          }
        }

        /* Month title */
        & .rdp-caption {
          margin: 0;
          padding: 0;
          padding-bottom: ${({ theme }) => theme.spacing(5)};
          text-transform: capitalize;
          display: flex;

          & > div {
            padding: 0;
            ${heading5};
            color: ${({ theme }) => theme.color.text.grey3} !important;
          }
        }

        /* Weekday header */
        & .rdp-head {
          & .rdp-head_row {
            & .rdp-head_cell {
              ${heading7};
              color: ${({ theme }) => theme.color.text.grey3} !important;
            }
          }
        }
      }
    }

    & .rdp-tbody {
      & .rdp-row {
        & .rdp-cell {
          border-radius: 0 !important;
          background-color: ${({ theme }) =>
            theme.color.background.white} !important;

          /* Past days */
          & .rdp-day_disabled {
            text-decoration: line-through;
            color: ${({ theme }) => theme.color.text.greyMouse} !important;
          }

          /* Today cell */
          & .rdp-day_today {
            color: ${({ theme }) => theme.color.text.blueDark} !important;
            font-weight: bold;

            &.rdp-day_selected {
              color: ${({ theme }) => theme.color.text.white} !important;
            }
          }

          /* Weekend cells */
          & .rdp-day:has(.week-end):not(.rdp-day_selected) {
            background-color: ${({ theme }) =>
              theme.color.background.greyLight} !important;
            border-radius: 0px !important;
          }

          /* Selectable hovered cell (not selected, not disabled, and not outside the selectable date) */
          & :not(.rdp-day_selected):not(.rdp-day_disabled):hover {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
            border-radius: 4px !important;
          }

          /* Selected cells (not the first one, and not the last one) */
          & .rdp-day_range_middle {
            background-color: ${({ theme }) =>
              theme.color.brand.blue} !important;
            color: ${({ theme }) => theme.color.text.white};
          }

          /* First selected cell */
          & .rdp-day_range_start {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
            background-color: ${({ theme }) =>
              theme.color.brand.blue} !important;
            color: ${({ theme }) => theme.color.text.white};
          }

          /* Last selected cell */
          & .rdp-day_range_end {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
            background-color: ${({ theme }) =>
              theme.color.brand.blue} !important;
            color: ${({ theme }) => theme.color.text.white};
          }
        }
      }
    }

    ${({ $showMonthNavigation }) =>
      $showMonthNavigation
        ? css`
            & .rdp-nav {
              position: relative;
              top: auto;
              right: auto;
              left: auto;
              transform: none;
            }

            & .rdp-nav_button {
              & .rdp-nav_icon {
                display: none;
              }
            }

            & .rdp-nav_button_previous {
              background-image: url(${KeyboardArrowLeft?.src}) !important;
              background-size: 100% 100% !important;
              background-repeat: no-repeat;
            }

            & .rdp-nav_button_next {
              background-image: url(${KeyboardArrowRight?.src}) !important;
              background-size: 100% 100% !important;
              background-repeat: no-repeat;
            }
          `
        : css`
            & .rdp-caption {
              justify-content: center;
            }

            & .rdp-nav {
              display: none;
            }
          `}
  }
`;

export type CalendarProps = {
  orientation: "horizontal" | "vertical";
  firstSelectableDay: Date;
  lastSelectableDay: Date;
  numberOfVisibleMonths?: number;
  defaultSelection?: { from: Date; to: Date };
  onSelectedDatesChange: (newSelection: { from: Date; to: Date }) => void;
};

/**
 * This is a workaround to convert a local date to its UTC equivalent.
 * This does not works all the time (see the links bellow) but it should be enough for us.
 *
 * @see https://github.com/date-fns/date-fns/issues/1401#issuecomment-578580199
 * @see https://github.com/date-fns/date-fns/issues/1401#issuecomment-621897094
 */
const toUtc = (someDate: Date): Date =>
  addMinutes(someDate, -someDate.getTimezoneOffset());

export const Calendar = ({
  orientation,
  defaultSelection,
  firstSelectableDay,
  lastSelectableDay,
  numberOfVisibleMonths,
  onSelectedDatesChange,
}: CalendarProps) => {
  const locale = useLocale();

  const [range, setRange] = useState<DateRange>(
    () => defaultSelection ?? { from: undefined, to: undefined },
  );

  const [currentVisibleMonth, setCurrentVisibleMonth] = useState(
    () => firstSelectableDay,
  );

  const handleDaySelected = useCallback(
    (selectedDay: Date) => {
      if (startOfDay(selectedDay) < startOfDay(firstSelectableDay)) {
        return;
      }

      if (!range.from && !range.to) {
        setRange({ ...range, from: selectedDay });
      } else if (range.from && selectedDay < range.from) {
        setRange({ from: selectedDay, to: undefined });
      } else if (
        range.from &&
        !range.to &&
        !isSameDay(range.from, selectedDay)
      ) {
        setRange({ ...range, to: selectedDay });

        onSelectedDatesChange({
          from: toUtc(startOfDay(range.from)),
          to: toUtc(startOfDay(selectedDay)),
        });
      } else {
        setRange({ from: selectedDay, to: undefined });
      }
    },
    [firstSelectableDay, onSelectedDatesChange, range],
  );

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentVisibleMonth(month);
  }, []);

  // A modifier will add a custom class name on the matched date (useful to customize them)
  const modifiers = useMemo<DayModifiers>(() => {
    let modifiers: any = { dayOfWeek: [0, 6] };
    if (range.from !== undefined) {
      modifiers = { ...modifiers, from: range.from };
    }
    if (range.to !== undefined) {
      modifiers = { ...modifiers, to: range.to };
    }

    return modifiers;
  }, [range.from, range.to]);

  const numberOfMonths = useMemo(
    () =>
      numberOfVisibleMonths ??
      differenceInCalendarMonths(
        startOfMonth(addMonths(lastSelectableDay, 1)),
        startOfMonth(firstSelectableDay),
      ),
    [numberOfVisibleMonths, firstSelectableDay, lastSelectableDay],
  );

  return (
    <CalendarRoot
      $orientation={orientation}
      $showMonthNavigation={orientation === "horizontal"}
    >
      <ReactDayPickerDynamic
        mode="range"
        className="Selectable"
        disabled={[
          { before: firstSelectableDay },
          { after: lastSelectableDay },
        ]}
        selected={range}
        fromMonth={firstSelectableDay}
        toMonth={lastSelectableDay}
        month={currentVisibleMonth}
        numberOfMonths={numberOfMonths}
        modifiers={modifiers}
        onDayClick={handleDaySelected}
        onMonthChange={handleMonthChange}
        locale={locale === AVAILABLE_LOCALES.en ? enUS : fr}
        labels={{
          labelPrevious: () => t`Previous month`,
          labelNext: () => t`Next month`,
        }}
        formatters={{
          formatDay: (date: Date) => {
            const dayOfWeek = date.getDay();
            const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

            return (
              <span className={isWeekend ? "week-end" : undefined}>
                {date.toLocaleDateString(locale, { day: "numeric" })}
              </span>
            );
          },
        }}
      />
    </CalendarRoot>
  );
};

export default Calendar;
