import { addDays, isBefore } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { CancellationPolicyId } from "~/applications/OfferSearch/Domain/CancellationPolicyId";
import { TravelPurpose } from "~/applications/OfferSearch/Domain/TravelPurpose";
import StayRequest from "~/core/StayRequest";
import useToday from "~/core/useToday";

const compileFirstBookableDay = (
  today: Date,
  destinationFirstBookableDate: Date | null,
): Date => {
  if (destinationFirstBookableDate === null) {
    return today;
  }

  return isBefore(today, destinationFirstBookableDate)
    ? destinationFirstBookableDate
    : today;
};

export type StayDates = { checkIn: Date; checkOut: Date };

export type Guests = {
  nbAdults: number;
  nbChildren: number;
  nbBabies: number;
};

type SearchableDestination = {
  seoSlug: string;
  firstBookableDate: Date | null;
};

export const useNewSearch = ({
  defaultDestinationSlug,
  defaultGuests,
  defaultStayDates,
  defaultCancellationPolicyId,
  defaultPromoCode,
  destinations,
}: {
  defaultDestinationSlug: string;
  defaultGuests?: Guests;
  defaultStayDates?: StayDates;
  defaultCancellationPolicyId?: CancellationPolicyId;
  defaultPromoCode?: string;
  destinations: Array<SearchableDestination>;
}) => {
  const [selectedDestinationSeoSlug, setSelectedDestinationSeoSlug] =
    useState<string>(defaultDestinationSlug);

  const selectedDestination = useMemo<SearchableDestination>(() => {
    const selectedDestinationSlug =
      selectedDestinationSeoSlug ?? defaultDestinationSlug;
    if (!selectedDestinationSlug) {
      if (destinations.length === 0) {
        throw new Error(
          "Empty destination list. Could not select a default destination.",
        );
      }

      return destinations[0];
    }

    const selectedDestination = destinations.find(
      (oneDestination) => selectedDestinationSlug === oneDestination.seoSlug,
    );

    if (!selectedDestination) {
      throw new Error(
        `Could not find the selected (or default) destination in the available destinations. Default slug: ${JSON.stringify(
          defaultDestinationSlug,
        )}". Selected slug: ${JSON.stringify(
          selectedDestinationSlug,
        )}. Available: ${JSON.stringify(
          destinations.map((oneDestination) => oneDestination.seoSlug),
        )}`,
      );
    }

    return selectedDestination;
  }, [selectedDestinationSeoSlug, defaultDestinationSlug, destinations]);

  const today = useToday();
  const firstBookableDay = compileFirstBookableDay(
    today,
    selectedDestination?.firstBookableDate ?? null,
  );

  const oneDayAfterFirstBookableDay = useMemo(
    () => addDays(firstBookableDay, 1),
    [firstBookableDay],
  );

  const [guests, setGuests] = useState<Guests | undefined>(defaultGuests);

  const [stayDates, setStayDates] = useState<StayDates | undefined>(
    defaultStayDates,
  );

  const [travelPurpose, setTravelPurpose] = useState<TravelPurpose>(
    TravelPurpose.LEISURE,
  );

  const [selectedCancellationPolicyId, setCancellationPolicyId] =
    useState<CancellationPolicyId>(
      defaultCancellationPolicyId ?? CancellationPolicyId.STANDARD,
    );

  const [promoCode, setPromoCode] = useState<string | undefined>(
    defaultPromoCode,
  );

  const addPromoCode = useCallback(
    (promoCode: string) => setPromoCode(promoCode),
    [],
  );

  const removePromoCode = useCallback(() => setPromoCode(undefined), []);

  const selectedStayRequest = useMemo(() => {
    const selectedGuests =
      guests !== undefined
        ? {
            nbAdults: guests.nbAdults > 0 ? guests.nbAdults : 1,
            nbChildren: guests.nbChildren,
            nbBabies: guests.nbBabies,
          }
        : {
            nbAdults: 1,
            nbChildren: 0,
            nbBabies: 0,
          };

    return new StayRequest(
      stayDates?.checkIn ?? firstBookableDay,
      stayDates?.checkOut ?? oneDayAfterFirstBookableDay,
      selectedGuests.nbAdults,
      selectedGuests.nbChildren,
      selectedGuests.nbBabies,
      travelPurpose,
      promoCode,
    );
  }, [
    stayDates?.checkIn,
    stayDates?.checkOut,
    guests,
    firstBookableDay,
    oneDayAfterFirstBookableDay,
    travelPurpose,
    promoCode,
  ]);

  return {
    selectedDestinationSeoSlug,
    setSelectedDestinationSeoSlug,

    guests,
    setGuests,

    stayDates,
    setStayDates,

    travelPurpose,
    setTravelPurpose,

    setCancellationPolicyId,

    addPromoCode,
    removePromoCode,

    selectedStayRequest,
    selectedCancellationPolicyId,
  };
};

export const useSearchFromQuery = ({
  destinationSlug,
  stayRequest,
  cancellationPolicyId,
  destinations,
}: {
  destinationSlug: string;
  stayRequest: StayRequest;
  cancellationPolicyId: CancellationPolicyId;
  destinations: Array<SearchableDestination>;
}) => {
  const selectedSearchableDestination = useMemo<SearchableDestination>(() => {
    const selectedDestination = destinations.find(
      (oneDestination) => destinationSlug === oneDestination.seoSlug,
    );

    if (!selectedDestination) {
      throw new Error(
        `Could not find the selected (or default) destination in the available destinations. Destination slug: ${JSON.stringify(
          destinationSlug,
        )}". Available: ${JSON.stringify(
          destinations.map((oneDestination) => oneDestination.seoSlug),
        )}`,
      );
    }

    return selectedDestination;
  }, [destinations, destinationSlug]);

  return {
    selectedSearchableDestination,
    selectedStayRequest: stayRequest,
    selectedCancellationPolicyId: cancellationPolicyId,
  };
};

export const useOptionalSearchFromQuery = ({
  destinationSlug,
  stayRequest,
  cancellationPolicyId,
  destinations,
}: {
  destinationSlug: string;
  stayRequest: StayRequest | undefined;
  cancellationPolicyId: CancellationPolicyId | undefined;
  destinations: Array<SearchableDestination>;
}) => {
  const selectedSearchableDestination = useMemo<SearchableDestination>(() => {
    const selectedDestination = destinations.find(
      (oneDestination) => destinationSlug === oneDestination.seoSlug,
    );

    if (!selectedDestination) {
      throw new Error(
        `Could not find the selected (or default) destination in the available destinations. Destination slug: ${JSON.stringify(
          destinationSlug,
        )}". Available: ${JSON.stringify(
          destinations.map((oneDestination) => oneDestination.seoSlug),
        )}`,
      );
    }

    return selectedDestination;
  }, [destinations, destinationSlug]);

  return {
    selectedSearchableDestination,
    selectedStayRequest: stayRequest,
    selectedCancellationPolicyId: cancellationPolicyId,
  };
};
