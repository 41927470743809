import { useButton } from "@react-aria/button";
import { useNumberField } from "@react-aria/numberfield";
import { useNumberFieldState } from "@react-stately/numberfield";
import { NumberFieldProps } from "@react-types/numberfield";
import React, { RefObject } from "react";
import styled from "styled-components";
import { useLocale } from "~/core/useLocale";
import { Add, Remove } from "~/guidelines/Icon";
import { body1, heading6, heading7 } from "~/guidelines/Typography";

const Field = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${({ theme }) => theme.spacing(6)};
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  ${heading6};
`;

const Description = styled.div`
  ${body1};
  color: ${({ theme }) => theme.color.text.greyMouse};
  white-space: nowrap;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
`;

const StepperButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.color.line.greyStroke};
  border-radius: 100%;
  box-shadow: ${({ theme }) => theme.shadow.navigation};
  background-color: ${({ theme }) => theme.color.background.white};
  cursor: pointer;
  color: ${({ theme }) => theme.color.text.blueDark};

  & svg {
    width: 16px;
  }

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.color.text.greyMouse};
  }
`;

const InputAsText = styled.input`
  width: 24px;
  margin: 0 8px;
  appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;
  text-align: center;

  /* Safari iOS add change the opacity of disabled input */
  opacity: 1;
  &:disabled {
    opacity: 1;
  }

  ${heading7};
`;

export type DecrementIncrementFieldProps = Omit<
  NumberFieldProps,
  "isReadOnly"
> & {
  label: string;
  description?: string;
  decrementAriaLabel: string;
  incrementAriaLabel: string;
};

const DecrementIncrementField = (props: DecrementIncrementFieldProps) => {
  const locale = useLocale();

  const state = useNumberFieldState({
    ...props,
    locale,
  });

  const inputRef =
    React.useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  const incrementButtonRef =
    React.useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;
  const decrementButtonRef =
    React.useRef<HTMLButtonElement>() as RefObject<HTMLButtonElement>;

  const {
    labelProps,
    descriptionProps,
    groupProps,
    inputProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField({ ...props, isDisabled: true }, state, inputRef);

  const { buttonProps: incrementProps } = useButton(
    incrementButtonProps,
    incrementButtonRef,
  );
  const { buttonProps: decrementProps } = useButton(
    decrementButtonProps,
    decrementButtonRef,
  );

  return (
    <Field>
      <Label {...labelProps}>
        <Title>{props.label}</Title>
        <Description {...descriptionProps}>{props.description}</Description>
      </Label>

      <Group {...groupProps}>
        <StepperButton {...decrementProps} ref={decrementButtonRef}>
          <Remove />
        </StepperButton>

        <InputAsText {...inputProps} ref={inputRef} />

        <StepperButton {...incrementProps} ref={incrementButtonRef}>
          <Add />
        </StepperButton>
      </Group>
    </Field>
  );
};

export default DecrementIncrementField;
