import NiceModal, { NiceModalHocProps, useModal } from "@ebay/nice-modal-react";
import { Trans, t } from "@lingui/macro";
import React, { useState } from "react";
import styled from "styled-components";
import { TravelPurpose } from "~/applications/OfferSearch/Domain/TravelPurpose";
import GuestsSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/GuestsSelector";
import { Guests } from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import { formatGuestsAsNaturalString } from "~/components/GuestsFormatter";
import { Route } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import Drawer from "~/guidelines/Drawer/Drawer";
import EngagingLayout from "~/guidelines/Drawer/Layout/EngagingLayout";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const ContentSizer = styled.div`
  ${mediaQueries.md()} {
    width: 350px;
    max-width: 350px;
  }
`;

type GuestsSelectorDrawerParameters = {
  guests?: Guests;
  travelPurpose: TravelPurpose;
  onSubmit: (newGuests: Guests, travelPurpose: TravelPurpose) => void;
  offerMaxCapacity: number | undefined;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
};

export type GuestsSelectorDrawerProps = NiceModalHocProps &
  GuestsSelectorDrawerParameters;

const GuestsSelectorDrawer = NiceModal.create<GuestsSelectorDrawerParameters>(
  ({ guests, travelPurpose, onSubmit, offerMaxCapacity }) => {
    const modal = useModal();

    const [selectedGuests, setSelectedGuests] = useState<Guests>(
      guests ?? { nbAdults: 1, nbBabies: 0, nbChildren: 0 },
    );

    const [selectedTravelPurpose, setSelectedTravelPurpose] =
      useState<TravelPurpose>(travelPurpose);

    return (
      <Drawer
        isOpen={modal.visible}
        title={t`Select the guests`}
        variant="raw"
        header={
          <EngagingLayout.Header
            title={t`Select the guests`}
            subtitle={formatGuestsAsNaturalString(
              selectedGuests.nbAdults,
              selectedGuests.nbChildren,
              selectedGuests.nbBabies,
            )}
          />
        }
        footer={
          <EngagingLayout.Footer>
            <Button
              size="large"
              fluid
              onPress={() => {
                modal.hide();
                onSubmit(selectedGuests, selectedTravelPurpose);
              }}
              isDisabled={
                offerMaxCapacity !== undefined
                  ? offerMaxCapacity <
                    selectedGuests.nbAdults + selectedGuests.nbChildren
                  : false
              }
            >
              <Trans>Confirm</Trans>
            </Button>
          </EngagingLayout.Footer>
        }
        onClose={modal.hide}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
      >
        <EngagingLayout.Content>
          <ContentSizer>
            <GuestsSelector
              selectedGuests={selectedGuests}
              selectedTravelPurpose={selectedTravelPurpose}
              onChange={(newGuests, newTravelPurpose) => {
                setSelectedGuests(newGuests);
                setSelectedTravelPurpose(newTravelPurpose);
              }}
            />
          </ContentSizer>
        </EngagingLayout.Content>
      </Drawer>
    );
  },
);

export default GuestsSelectorDrawer;
