import { VisuallyHidden } from "@react-aria/visually-hidden";
import { AriaSwitchProps } from "@react-types/switch";
import { motion, useAnimation, useSpring } from "framer-motion";
import React, { RefObject, useEffect, useRef } from "react";
import { useFocusRing, useSwitch } from "react-aria";
import { useToggleState } from "react-stately";
import styled from "styled-components";
import { useTheme } from "~/guidelines/Theme/useTheme";
import { body1 } from "~/guidelines/Typography";

const Toggle = ({
  isSelected,
  isDisabled,
  isFocusVisible,
}: {
  isSelected: boolean;
  isDisabled: boolean;
  isFocusVisible: boolean;
}) => {
  const theme = useTheme();
  const controls = useAnimation();

  const svgVariants = {
    enable: {
      opacity: 1,
    },
    disable: {
      opacity: 0.4,
    },
  };

  const backgroundRectVariants = {
    selected: {
      fill: theme.color.brand.blue,
    },
    unselected: {
      fill: theme.color.line.greyStroke,
    },
  };

  // const knobVariants = {
  //   selected: {
  //     opacity: 1,
  //   },
  //   unselected: {
  //     opacity: 0,
  //   },
  // };

  const knobX = useSpring(isSelected ? 24 : 7, { damping: 15 });
  const knobBackgroundCX = useSpring(isSelected ? 28 : 12, { damping: 15 });

  useEffect(() => {
    knobX.set(isSelected ? 24 : 7);
    knobBackgroundCX.set(isSelected ? 28 : 12);
    controls.start(isSelected ? "selected" : "unselected");
  }, [isSelected, controls, knobBackgroundCX, knobX]);

  useEffect(() => {
    controls.start(isDisabled ? "disable" : "enable");
  }, [isDisabled, controls]);

  return (
    <motion.svg
      width="40"
      height="24"
      aria-hidden="true"
      style={{
        borderRadius: "40px 40px 40px 40px / 40px 40px 40px 40px",
        outline: isFocusVisible ? theme.outline.base.outline : undefined,
        outlineOffset: isFocusVisible ? theme.outline.base.offset : undefined,
      }}
      initial="enable"
      animate={controls}
      variants={svgVariants}
    >
      <motion.rect
        x="0"
        y="0"
        width="40"
        height="24"
        rx="12"
        initial="unselected"
        animate={controls}
        variants={backgroundRectVariants}
      />

      <motion.circle
        cx={knobBackgroundCX}
        cy="12"
        r="9"
        fill={theme.color.background.white}
        style={{
          fill: isDisabled
            ? theme.shadow.navigation
            : theme.color.background.white,
          filter: `drop-shadow(${theme.shadow.navigation})`,
        }}
      />

      {/* <motion.path
        x={knobX}
        transform={`translate(${knobX}, 8)`}
        d="M3.19335 6.86L0.800015 4.46667C0.67518 4.34211 0.605024 4.17301 0.605024 3.99667C0.605024 3.82032 0.67518 3.65122 0.800015 3.52667C1.06001 3.26667 1.48001 3.26667 1.74001 3.52667L3.66668 5.44667L8.25335 0.860001C8.51335 0.600001 8.93335 0.600001 9.19335 0.860001C9.45335 1.12 9.45335 1.54 9.19335 1.8L4.13335 6.86C3.88001 7.12 3.45335 7.12 3.19335 6.86Z"
        fill={theme.color.brand.blue}
        initial="unselected"
        animate={controls}
        variants={knobVariants}
      /> */}
    </motion.svg>
  );
};

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;

  ${body1};
`;

const ToggleBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-basis: 40px;
`;

const TextBox = styled.div``;

const Switch = (props: AriaSwitchProps) => {
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  const { inputProps } = useSwitch(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Label>
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>

      <ToggleBox>
        <Toggle
          isDisabled={inputProps.disabled ?? false}
          isSelected={state.isSelected}
          isFocusVisible={isFocusVisible}
        />
      </ToggleBox>

      <TextBox>{props.children}</TextBox>
    </Label>
  );
};

export default Switch;
