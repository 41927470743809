import { t } from "@lingui/macro";
import { useListBox, useOption } from "@react-aria/listbox";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { Item } from "@react-stately/collections";
import { ListProps, ListState, useListState } from "@react-stately/list";
import { Node } from "@react-types/shared";
import React, { RefObject } from "react";
import styled from "styled-components";
import DestinationButton from "~/applications/Editorials/Ui/Destination/DestinationDrawer/DestinationButton";
import { UpcomingDestinations } from "~/applications/Editorials/Ui/Destination/DestinationDrawer/UpcomingDestinations";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import { ResourceVisibility } from "~/core/ResourceVisibility";

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  margin: 0;
  padding: 0;
  list-style: none;
`;

type DestinationItem = { destination: DestinationMenuEntry };

type DestinationOptionProps = {
  item: Node<DestinationItem>;
  state: ListState<DestinationItem>;
};

const DestinationOption = ({ item, state }: DestinationOptionProps) => {
  const destination = item.value?.destination;
  const ref = React.useRef<HTMLLIElement>() as RefObject<HTMLLIElement>;
  const { optionProps, isSelected } = useOption(
    {
      key: item.key,
      "aria-label": item.textValue,
      isDisabled: destination?.firstBookableDate === null,
    },
    state,
    ref,
  );

  if (destination === undefined) {
    return <></>;
  }

  return (
    <DestinationButton
      {...optionProps}
      ref={ref}
      as="li"
      isSelected={isSelected}
      destinationName={item.textValue}
      illustration={destination?.menuDrawing.url}
      teasingText={
        destination.firstBookableDate === null ? t`Opening 2024` : undefined
      }
      isDisabled={destination.firstBookableDate === null}
    />
  );
};

const DestinationListBox = (
  props: ListProps<DestinationItem> & { label: string },
) => {
  const state = useListState(props);

  const ref = React.useRef(null) as RefObject<HTMLUListElement>;
  const { listBoxProps, labelProps } = useListBox(props, state, ref);

  return (
    <>
      <VisuallyHidden>
        <div {...labelProps}>{props.label}</div>
      </VisuallyHidden>

      <StyledList {...listBoxProps} ref={ref}>
        {[...state.collection].map((item) => (
          <DestinationOption key={item.key} item={item} state={state} />
        ))}
      </StyledList>
    </>
  );
};

export type DestinationSelectorProps = {
  selectedDestination: DestinationMenuEntry | undefined;
  destinations: Array<DestinationMenuEntry>;
  onChange?: (newDestinationId: DestinationMenuEntry) => void;
};

export const DestinationSelector = ({
  selectedDestination,
  destinations,
  onChange,
}: DestinationSelectorProps) => {
  return (
    <Selector.Layout>
      <DestinationListBox
        label={t`Select a destination`}
        selectionMode="single"
        disallowEmptySelection
        defaultSelectedKeys={
          selectedDestination?.sanityId
            ? [selectedDestination.sanityId]
            : undefined
        }
        disabledKeys={
          selectedDestination?.sanityId
            ? [selectedDestination.sanityId]
            : undefined
        }
        items={destinations
          .filter(
            (oneDestination) =>
              oneDestination.visibility === ResourceVisibility.PUBLIC,
          )
          .map((oneDestination) => ({
            key: oneDestination.sanityId,
            destination: oneDestination,
          }))}
        onSelectionChange={(keys) => {
          if (keys === "all") {
            throw new Error('Only one item should be selected. Actually "all"');
          }

          if (keys.size !== 1) {
            throw new Error(
              'Only one item should be selected. Actually "${keys.size}" elements',
            );
          }

          const selectedDestinationId = [...keys][0].toString();

          const newSelectedDestination = destinations.find(
            (oneDestination) =>
              selectedDestinationId === oneDestination.sanityId,
          );

          if (!newSelectedDestination) {
            throw new Error(
              "Could not find the newly selected destination in the available destinations",
            );
          }

          if (onChange !== undefined) {
            onChange(newSelectedDestination);
          }
        }}
      >
        {(item) => (
          <Item key={item.destination.sanityId}>{item.destination.name}</Item>
        )}
      </DestinationListBox>

      <UpcomingDestinations />
    </Selector.Layout>
  );
};

export default DestinationSelector;
