import React, { useState } from "react";
import styled, { css } from "styled-components";
import { SmallCenteredPulseLoader } from "~/guidelines/Loaders";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div<{ $isLoaded: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ $isLoaded }) =>
    $isLoaded
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 1;
        `}
  transition: opacity 0.2s ease-in-out;
`;

const Iframe = styled.iframe<{ $isLoaded: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  border: none;

  ${({ $isLoaded }) =>
    $isLoaded
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
  transition: opacity 0.5s ease-in-out;
`;

export const ZenChefTableBookingIFrame = ({
  zenChefRestaurantId,
}: {
  zenChefRestaurantId: string | null;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Container>
      <LoaderContainer $isLoaded={isLoaded}>
        {!isLoaded && <SmallCenteredPulseLoader />}
      </LoaderContainer>
      <Iframe
        $isLoaded={isLoaded}
        src={`https://bookings.zenchef.com/results?fullscreen=1&rid=${zenChefRestaurantId}`}
        onLoad={() => {
          setIsLoaded(true);
        }}
        data-testid="zenchef-iframe"
      />
    </Container>
  );
};
