import { t } from "@lingui/macro";
import React from "react";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import DestinationSelectorDrawer from "~/applications/OfferSearch/Ui/StayRequestForm/DestinationSelectorDrawer";
import { FakeSelectTrigger } from "~/applications/OfferSearch/Ui/StayRequestForm/Trigger/ClassicTrigger";
import { useModalController } from "~/core/modal";

export const DestinationDrawerWithClassicTrigger = ({
  destination,
  destinations,
  onDestinationChange,
}: {
  destination: DestinationMenuEntry | undefined;
  destinations: Array<DestinationMenuEntry>;
  onDestinationChange: (newDestination: DestinationMenuEntry) => void;
}) => {
  const destinationSelectorDrawerController = useModalController(
    DestinationSelectorDrawer,
  );

  return (
    <FakeSelectTrigger
      label={t`Destination`}
      onPress={() => {
        destinationSelectorDrawerController.show({
          defaultDestination: destination,
          destinations,
          onSubmit: onDestinationChange,
        });
      }}
    >
      {destination?.name ?? t`Select a destination`}
    </FakeSelectTrigger>
  );
};

export default DestinationDrawerWithClassicTrigger;
