import { plural, t } from "@lingui/macro";

export const formatGuestsAsNaturalString = (
  nbAdult: number,
  nbChildren: number,
  nbBabies: number,
): string => {
  const adults = plural(nbAdult, { one: "# adult", other: "# adults" });
  const children = plural(nbChildren, {
    one: "# child",
    other: "# children",
  });
  const babies = plural(nbBabies, { one: "# baby", other: "# babies" });
  const guests = plural(nbAdult + nbChildren + nbBabies, {
    one: "# guest",
    other: "# guests",
  });

  if (nbAdult > 0 && nbChildren > 0 && nbBabies > 0) {
    return t`${adults}, ${children} and ${babies}`;
  } else if (nbAdult > 0 && nbChildren > 0 && nbBabies === 0) {
    return t`${adults} and ${children}`;
  } else if (nbAdult > 0 && nbChildren === 0 && nbBabies > 0) {
    return t`${adults} and ${babies}`;
  } else if (nbAdult > 0 && nbChildren === 0 && nbBabies === 0) {
    return adults;
  } else if (nbAdult === 0 && nbChildren > 0 && nbBabies === 0) {
    return children;
  } else if (nbAdult === 0 && nbChildren === 0 && nbBabies > 0) {
    return babies;
  }

  return guests;
};
