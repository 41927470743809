import NiceModal, { NiceModalHocProps, useModal } from "@ebay/nice-modal-react";
import { Plural, Trans, t } from "@lingui/macro";
import { differenceInCalendarDays } from "date-fns";
import React, { useState } from "react";
import styled from "styled-components";
import DatesSelector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/DatesSelector";
import { StayDates } from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import { useLocale } from "~/core/useLocale";
import { Button } from "~/guidelines/Button";
import Drawer from "~/guidelines/Drawer/Drawer";
import EngagingLayout from "~/guidelines/Drawer/Layout/EngagingLayout";
import mediaQueries from "~/guidelines/Theme/mediaQueries";

const ContentSizer = styled.div`
  height: 100%;

  ${mediaQueries.md()} {
    width: 350px;
    max-width: 350px;
  }
`;

type DatesSelectorDrawerParameters = {
  firstAvailableDate: Date | null;
  defaultDates?: StayDates;
  onSubmit: (newStayDates: StayDates) => void;
};

export type DatesSelectorDrawerProps = NiceModalHocProps &
  DatesSelectorDrawerParameters;

const DatesSelectorDrawer = NiceModal.create<DatesSelectorDrawerParameters>(
  ({ firstAvailableDate, defaultDates, onSubmit }) => {
    const modal = useModal();

    const locale = useLocale();

    const [selectedDates, setSelectedDates] = useState<StayDates | undefined>(
      () => defaultDates,
    );

    return (
      <Drawer
        isOpen={modal.visible}
        variant="raw"
        backgroundColor="white"
        title={t`Select the dates of your stay`}
        header={
          <EngagingLayout.Header
            title={t`Select the dates of your stay`}
            subtitle={
              selectedDates ? (
                <>
                  <Plural
                    value={differenceInCalendarDays(
                      selectedDates?.checkOut,
                      selectedDates?.checkIn,
                    )}
                    one="# night"
                    other="# nights"
                  />{" "}
                  &ndash;{" "}
                  <Trans>
                    {selectedDates?.checkIn.toLocaleDateString(locale, {
                      weekday: "short",
                      day: "numeric",
                      month: "long",
                    })}{" "}
                    to{" "}
                    {selectedDates?.checkOut.toLocaleDateString(locale, {
                      weekday: "short",
                      day: "numeric",
                      month: "long",
                    })}
                  </Trans>
                </>
              ) : (
                <>&nbsp;</>
              )
            }
          />
        }
        footer={
          <EngagingLayout.Footer>
            <Button
              size="large"
              fluid
              isDisabled={selectedDates === undefined}
              onPress={() => {
                if (!selectedDates) {
                  return;
                }

                modal.hide();
                onSubmit(selectedDates);
              }}
            >
              <Trans>Confirm</Trans>
            </Button>
          </EngagingLayout.Footer>
        }
        onClose={modal.hide}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
      >
        <EngagingLayout.Content>
          <ContentSizer>
            <DatesSelector
              firstAvailableDate={firstAvailableDate}
              orientation="vertical"
              selectedDate={defaultDates}
              navigationKind="scroll"
              onChange={setSelectedDates}
            />
          </ContentSizer>
        </EngagingLayout.Content>
      </Drawer>
    );
  },
);

export default DatesSelectorDrawer;
