"use client";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Trans, t } from "@lingui/macro";
import useComponentSize from "@rehooks/component-size";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { CancellationPolicyId } from "~/applications/OfferSearch/Domain/CancellationPolicyId";
import { DestinationMenuEntry } from "~/applications/OfferSearch/Domain/DestinationPage";
import { TravelPurpose } from "~/applications/OfferSearch/Domain/TravelPurpose";
import CardStayRequestForm from "~/applications/OfferSearch/Ui/StayRequestForm/CardStayRequestForm";
import {
  Guests,
  StayDates,
  useNewSearch,
} from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import { ZenChefTableBookingIFrame } from "~/components/TableBookingIFrame";
import StayRequest from "~/core/StayRequest";
import { Route } from "~/core/routes";
import { Button } from "~/guidelines/Button";
import InformativeLayout from "~/guidelines/Drawer/Layout/InformativeLayout";
import { CenteredPulseLoader } from "~/guidelines/Loaders";
import { PillTab, PillTabList } from "~/guidelines/Navigation/PillTabs";
import mediaQueries from "~/guidelines/Theme/mediaQueries";
import WomanWithPillows from "~/guidelines/Drawings/WomanWithPillows.svg";

const DrawerDynamic = dynamic(() => import("~/guidelines/Drawer/Drawer"), {
  ssr: false,
});

const ContentSizer = styled.div`
  height: 100%;

  ${mediaQueries.md()} {
    width: 430px;
    max-width: 430px;
  }
`;

const BookLogeLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
  padding-bottom: ${({ theme }) => theme.spacing(4)};

  ${mediaQueries.md()} {
    padding-bottom: 0;
  }
`;

const DrawingContainer = styled.div<{ $isVisible: boolean }>`
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  display: ${({ $isVisible }) => ($isVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export enum BookingMode {
  Loges,
  Echoppes,
}

type BookDrawerModalParameters = {
  availableBookingMode?: Array<BookingMode>;
  initialBookingMode?: BookingMode;
  initialDestinationSlug: string;
  initialGuests?: Guests;
  initialStayDates?: StayDates;
  initialCancellationPolicyId?: CancellationPolicyId;
  initialPromoCode?: string;
  destinations: Array<DestinationMenuEntry>;
  onSearchRoomOffer: (roomsSearch: {
    destination: DestinationMenuEntry;
    stayRequest: StayRequest;
    cancellationPolicyId: CancellationPolicyId;
  }) => void;
  offerMaxCapacity: number | undefined;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
  zenChefRestaurantId: string | null;
};

const defaultBookingMode = [BookingMode.Loges, BookingMode.Echoppes];

const BookDrawerModal = NiceModal.create<BookDrawerModalParameters>(
  ({
    availableBookingMode = defaultBookingMode,
    initialBookingMode,
    initialGuests = undefined,
    initialStayDates = undefined,
    initialCancellationPolicyId = undefined,
    initialPromoCode = undefined,
    initialDestinationSlug,
    destinations,
    onSearchRoomOffer,
    offerMaxCapacity,
    onNewSearch,
    zenChefRestaurantId,
  }) => {
    const modal = useModal();

    const drawerContentRef = useRef(null);

    const { height: drawerContentHeight } = useComponentSize(drawerContentRef);

    const [bookingMode, setBookingMode] = useState<BookingMode>(
      initialBookingMode ??
        defaultBookingMode.filter((oneBookingMode) =>
          availableBookingMode.includes(oneBookingMode),
        )[0] ??
        BookingMode.Loges,
    );

    const {
      selectedDestinationSeoSlug,
      setSelectedDestinationSeoSlug,

      stayDates,
      guests,
      travelPurpose,

      setStayDates,
      setGuests,
      setTravelPurpose,

      selectedStayRequest,
      selectedCancellationPolicyId,
    } = useNewSearch({
      defaultDestinationSlug: initialDestinationSlug,
      defaultGuests: initialGuests,
      defaultStayDates: initialStayDates,
      defaultCancellationPolicyId: initialCancellationPolicyId,
      defaultPromoCode: initialPromoCode,
      destinations,
    });

    const selectedDestination = useMemo<DestinationMenuEntry>(() => {
      const selectedDestination = destinations.find(
        (oneDestination) =>
          oneDestination.seoSlug === selectedDestinationSeoSlug,
      );

      // this if will not be trigger because the same exist in useNewSearch.
      // But it's needed to clear the undefined type provided by find function
      if (!selectedDestination) {
        throw new Error(
          `Could not find the selected destination in the available destinations.
           Selected slug: ${selectedDestinationSeoSlug}.
           Available: ${destinations
             .map((oneDestination) => oneDestination.seoSlug)
             .join(",")}
          `,
        );
      }

      return selectedDestination;
    }, [destinations, selectedDestinationSeoSlug]);

    if (availableBookingMode.length === 0) {
      console.warn(
        "Could not show the book drawer modal. No booking mode available.",
      );

      return <></>;
    }

    return (
      <DrawerDynamic
        detent={
          bookingMode === BookingMode.Echoppes
            ? "full-height"
            : "content-height"
        }
        isOpen={modal.visible}
        variant="raw"
        backgroundColor="cream"
        title={t`Book a room or a table`}
        header={
          <InformativeLayout.Header title={t`Book`} onClose={modal.hide}>
            {availableBookingMode.length > 1 ? (
              <PillTabList layout="fluid">
                {availableBookingMode.includes(BookingMode.Loges) ? (
                  <PillTab
                    active={bookingMode === BookingMode.Loges}
                    onPress={() => setBookingMode(BookingMode.Loges)}
                  >
                    <Trans>Stay</Trans>
                  </PillTab>
                ) : undefined}

                {availableBookingMode.includes(BookingMode.Echoppes) ? (
                  <PillTab
                    active={bookingMode === BookingMode.Echoppes}
                    onPress={() => setBookingMode(BookingMode.Echoppes)}
                  >
                    <Trans>Taste</Trans>
                  </PillTab>
                ) : undefined}
              </PillTabList>
            ) : undefined}
          </InformativeLayout.Header>
        }
        footer={
          bookingMode === BookingMode.Loges ? (
            <InformativeLayout.Footer>
              <Button
                size="large"
                fluid
                onPress={() => {
                  onSearchRoomOffer({
                    destination: selectedDestination,
                    stayRequest: selectedStayRequest,
                    cancellationPolicyId: selectedCancellationPolicyId,
                  });
                }}
              >
                <Trans>Search</Trans>
              </Button>
            </InformativeLayout.Footer>
          ) : undefined
        }
        onClose={() => {
          modal.hide();
        }}
        onAfterClose={() => {
          if (!modal.keepMounted) {
            modal.remove();
          }
        }}
      >
        <ContentSizer ref={drawerContentRef}>
          <InformativeLayout.Content
            isFullWith={bookingMode === BookingMode.Echoppes}
            isFullHeight
          >
            {bookingMode === BookingMode.Loges ? (
              <BookLogeLayout>
                {destinations !== null ? (
                  <>
                    <CardsContainer>
                      <CardStayRequestForm
                        destination={selectedDestination}
                        destinations={destinations}
                        stayDates={stayDates}
                        guests={guests}
                        travelPurpose={travelPurpose}
                        onStayDatesChange={setStayDates}
                        onGuestsChange={setGuests}
                        onTravelPurposeChange={setTravelPurpose}
                        onDestinationChange={(
                          newDestination: DestinationMenuEntry,
                        ) => {
                          setSelectedDestinationSeoSlug(newDestination.seoSlug);
                        }}
                        offerMaxCapacity={offerMaxCapacity}
                        onNewSearch={onNewSearch}
                      />
                    </CardsContainer>

                    <DrawingContainer $isVisible={drawerContentHeight > 400}>
                      <Image
                        src={WomanWithPillows}
                        width={150}
                        height={100}
                        alt=""
                      />
                    </DrawingContainer>
                  </>
                ) : (
                  <CenteredPulseLoader />
                )}
              </BookLogeLayout>
            ) : undefined}

            {bookingMode === BookingMode.Echoppes ? (
              <ZenChefTableBookingIFrame
                zenChefRestaurantId={zenChefRestaurantId}
              />
            ) : undefined}
          </InformativeLayout.Content>
        </ContentSizer>
      </DrawerDynamic>
    );
  },
);

export default BookDrawerModal;
