import { t } from "@lingui/macro";
import React from "react";
import styled from "styled-components";
import { TravelPurpose } from "~/applications/Checkout/Domain/TravelPurpose";
import Selector from "~/applications/OfferSearch/Ui/StayRequestForm/Selector/Selector";
import DecrementIncrementField from "~/guidelines/Form/DecrementIncrementField";
import Switch from "~/guidelines/Form/Switch";
import { subtitle2 } from "~/guidelines/Typography";

const SwitchLabel = styled.div`
  ${subtitle2};
`;

export type GuestsSelectorProps = {
  selectedGuests: {
    nbAdults: number;
    nbChildren: number;
    nbBabies: number;
  };
  selectedTravelPurpose: TravelPurpose;
  onChange?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => void;
};

export const GuestsSelector = ({
  selectedGuests,
  selectedTravelPurpose,
  onChange,
}: GuestsSelectorProps) => {
  return (
    <>
      <Selector.Layout>
        <DecrementIncrementField
          label={t`Adult`}
          description={t`From 18 year old`}
          decrementAriaLabel={t`Remove one adult`}
          incrementAriaLabel={t`Add one adult`}
          minValue={1}
          maxValue={12}
          value={selectedGuests.nbAdults}
          onChange={(newNbAdults) => {
            if (onChange !== undefined) {
              onChange(
                { ...selectedGuests, nbAdults: newNbAdults },
                selectedTravelPurpose,
              );
            }
          }}
        />
        <DecrementIncrementField
          label={t`Child`}
          description={t`From 3 to 17 year old`}
          decrementAriaLabel={t`Remove one child`}
          incrementAriaLabel={t`Add one child`}
          minValue={0}
          maxValue={11}
          value={selectedGuests.nbChildren}
          onChange={(newNbChildren) => {
            if (onChange !== undefined) {
              onChange(
                { ...selectedGuests, nbChildren: newNbChildren },
                selectedTravelPurpose,
              );
            }
          }}
        />
        <DecrementIncrementField
          label={t`Baby`}
          description={t`From 0 to 2 year old`}
          decrementAriaLabel={t`Remove one baby`}
          incrementAriaLabel={t`Add one baby`}
          minValue={0}
          maxValue={8}
          value={selectedGuests.nbBabies}
          onChange={(newNbBabies) => {
            if (onChange !== undefined) {
              onChange(
                { ...selectedGuests, nbBabies: newNbBabies },
                selectedTravelPurpose,
              );
            }
          }}
        />

        <Switch
          aria-label={t`Travel purpose`}
          defaultSelected={selectedTravelPurpose === TravelPurpose.BUSINESS}
          isSelected={selectedTravelPurpose === TravelPurpose.BUSINESS}
          onChange={(isSelected) => {
            const newTravelPurpose = isSelected
              ? TravelPurpose.BUSINESS
              : TravelPurpose.LEISURE;

            if (onChange !== undefined) {
              onChange(selectedGuests, newTravelPurpose);
            }
          }}
        >
          <SwitchLabel>{t`I'm traveling for work`}</SwitchLabel>
        </Switch>
      </Selector.Layout>
    </>
  );
};

export default GuestsSelector;
