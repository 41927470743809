import { z } from "zod";

export const SanityImagePayloadSchema = z.object({
  url: z.string(),
});

export const SanityImageWithMetadataPayloadSchema =
  SanityImagePayloadSchema.extend({
    metadata: z.object({
      lqip: z.string().nullable().optional().default(null),
      dimensions: z.object({
        width: z.number(),
        height: z.number(),
      }),
    }),
  });
