import { t } from "@lingui/macro";
import React from "react";
import DatesSelectorDrawer from "~/applications/OfferSearch/Ui/StayRequestForm/DatesSelectorDrawer";
import { FakeSelectTrigger } from "~/applications/OfferSearch/Ui/StayRequestForm/Trigger/ClassicTrigger";
import { useModalController } from "~/core/modal";
import { useLocale } from "~/core/useLocale";

const DatesDrawerWithClassicTrigger = ({
  firstAvailableDate,
  dates,
  onDatesChange,
}: {
  firstAvailableDate: Date | null;
  dates?: { checkIn: Date; checkOut: Date };
  onDatesChange: (newDates: { checkIn: Date; checkOut: Date }) => void;
}) => {
  const locale = useLocale();

  const datesSelectorDrawerController = useModalController(DatesSelectorDrawer);

  return (
    <FakeSelectTrigger
      label={t`Arrival and departure`}
      onPress={() => {
        datesSelectorDrawerController.show({
          firstAvailableDate,
          defaultDates: dates,
          onSubmit: onDatesChange,
        });
      }}
    >
      {dates !== undefined
        ? t`${dates.checkIn.toLocaleDateString(locale, {
            weekday: "short",
            day: "2-digit",
            month: "long",
          })} to ${dates.checkOut.toLocaleDateString(locale, {
            weekday: "short",
            day: "2-digit",
            month: "long",
          })}`
        : t`Select the dates`}
    </FakeSelectTrigger>
  );
};

export default DatesDrawerWithClassicTrigger;
