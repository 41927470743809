import { t } from "@lingui/macro";
import React, { useCallback } from "react";
import { TravelPurpose } from "~/applications/Checkout/Domain/TravelPurpose";
import GuestsSelectorDrawer from "~/applications/OfferSearch/Ui/StayRequestForm/GuestsSelectorDrawer";
import { FakeSelectTrigger } from "~/applications/OfferSearch/Ui/StayRequestForm/Trigger/ClassicTrigger";
import { Guests } from "~/applications/OfferSearch/Ui/StayRequestForm/useSearch";
import { formatGuestsAsNaturalString } from "~/components/GuestsFormatter";
import { useModalController } from "~/core/modal";
import { Route } from "~/core/routes";

const GuestsDrawerWithClassicTrigger = ({
  guests,
  onGuestsChange,
  travelPurpose,
  onNewSearch,
  onTravelPurposeChange,
  offerMaxCapacity,
}: {
  guests?: {
    nbAdults: number;
    nbChildren: number;
    nbBabies: number;
  };
  onGuestsChange: (newGuests: Guests) => void;
  travelPurpose: TravelPurpose;
  onTravelPurposeChange: (newTravelPurpose: TravelPurpose) => void;
  offerMaxCapacity: number | undefined;
  onNewSearch?: (
    newGuests: {
      nbAdults: number;
      nbChildren: number;
      nbBabies: number;
    },
    newTravelPurpose: TravelPurpose,
  ) => Route;
}) => {
  const guestsSelectorDrawerController =
    useModalController(GuestsSelectorDrawer);

  const handleOnSubmit = useCallback(
    (newGuests: Guests, newTravelPurpose: TravelPurpose) => {
      onGuestsChange(newGuests);
      onTravelPurposeChange(newTravelPurpose);
    },
    [onGuestsChange, onTravelPurposeChange],
  );

  return (
    <FakeSelectTrigger
      label={t`Number of guests`}
      onPress={() => {
        guestsSelectorDrawerController.show({
          guests,
          travelPurpose,
          onSubmit: handleOnSubmit,
          offerMaxCapacity,
          onNewSearch,
        });
      }}
    >
      {guests !== undefined &&
      (guests.nbAdults > 0 || guests.nbChildren > 0 || guests.nbBabies > 0)
        ? formatGuestsAsNaturalString(
            guests.nbAdults,
            guests.nbChildren,
            guests.nbBabies,
          )
        : t`Select the guests`}
    </FakeSelectTrigger>
  );
};

export default GuestsDrawerWithClassicTrigger;
