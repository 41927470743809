import Image from "next/image";
import React, { useCallback } from "react";
import { mergeProps, useFocusRing } from "react-aria";
import styled, { css } from "styled-components";
import { heading5, subtitle2 } from "~/guidelines/Typography";

const Container = styled.div<{
  $isSelected: boolean;
  $isFocusVisible: boolean;
  $isDisabled: boolean;
  $isTeasing: boolean;
}>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? `default` : `pointer`)};
  margin: 0;
  padding: 0;

  background-color: ${({ theme, $isTeasing }) =>
    $isTeasing
      ? theme.color.background.creamDark
      : theme.color.background.cream};
  border: 1px solid
    ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.color.background.blueDark
        : theme.color.line.greyStroke};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(7)};
  padding: ${({ theme }) => theme.spacing(7)};

  transition:
    box-shadow ease-out 250ms,
    border ease-out 250ms,
    font-weight ease-out 250ms;

  ${({ $isSelected, $isDisabled }) =>
    !$isSelected && !$isDisabled
      ? css`
          &:hover {
            box-shadow: ${({ theme }) => theme.shadow.navigation};
          }
        `
      : null};

  ${({ $isSelected, $isDisabled }) =>
    $isSelected && !$isDisabled
      ? css`
          border: 1px solid ${({ theme }) => theme.color.brand.blue};
          box-shadow: ${({ theme }) => theme.shadow.navigation};
        `
      : null};

  outline: ${({ $isFocusVisible, $isDisabled, theme }) =>
    $isFocusVisible && !$isDisabled
      ? `2px solid ${theme.color.brand.blue};`
      : "none"};
  box-shadow: ${({ $isFocusVisible, $isDisabled, theme }) =>
    $isFocusVisible && !$isDisabled ? theme.shadow.navigation : "none"};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TeasingText = styled.div`
  ${subtitle2};
  color: ${({ theme }) => theme.color.brand.red};
`;

const DestinationName = styled.div`
  ${heading5};
`;

export type DestinationButtonProps = {
  as?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isFocusVisible?: boolean;
  teasingText?: string;
  destinationName: string;
  illustration: string;
  onSelectDestination?: () => void;
};

const DestinationButton = React.forwardRef<HTMLElement, DestinationButtonProps>(
  function DestinationButtonForwardingRef(
    {
      as = "div",
      isSelected = false,
      isDisabled = false,
      teasingText,
      destinationName,
      illustration,
      onSelectDestination,
      ...props
    },
    forwardedRef,
  ) {
    const { isFocusVisible, focusProps } = useFocusRing();

    const handleClick = useCallback(() => {
      if (onSelectDestination === undefined) {
        return;
      }

      onSelectDestination();
    }, [onSelectDestination]);

    return (
      <Container
        {...mergeProps(props, focusProps)}
        as={as as any}
        ref={forwardedRef}
        $isSelected={isSelected}
        $isFocusVisible={isFocusVisible}
        $isDisabled={isDisabled}
        $isTeasing={teasingText !== undefined}
        onClick={handleClick}
      >
        <Image alt="" src={illustration} width={90} height={60} />
        <ContentWrapper>
          <DestinationName>{destinationName}</DestinationName>
          {teasingText && <TeasingText>{teasingText}</TeasingText>}
        </ContentWrapper>
      </Container>
    );
  },
);

export default DestinationButton;
