import { DestinationSpecificities } from "~/applications/OfferSearch/Domain/DestinationSpecificities";
import { ResourceVisibility } from "~/core/ResourceVisibility";
import { ImageWithMetadata } from "~/core/image";

type SEO = {
  picture: ImageWithMetadata;
  description: string;
};

type LifeInDestination = {
  description: string;
  specificities: Array<DestinationSpecificities>;
};

type DestinationPageEditorial = {
  heroBackgroundPicture: ImageWithMetadata;
  pictureGalleryDescription: string;
  lifeInDestination: LifeInDestination;
  seo: SEO;
};

export type DestinationPage = {
  seoSlug: string;
  destination: {
    sanityId: string;
    name: string;
    images: Array<{ asset: ImageWithMetadata; caption: string }>;
  };
  visibility: ResourceVisibility;
  menuDrawing: ImageWithMetadata;
  photo: ImageWithMetadata;
  introduction: string;
  firstBookableDate: Date | null;
  zenChefRestaurantId: string | null;
  features: {
    guestCanBookARoom: boolean;
    destinationHaveEchoppePage: boolean;
    guestCanBookATable: boolean;
  };
  destinationPageEditorial: DestinationPageEditorial;
};

export type DestinationMenuEntry = {
  sanityId: string;
  seoSlug: string;
  name: string;
  menuDrawing: ImageWithMetadata;
  visibility: ResourceVisibility;
  firstBookableDate: Date | null;
};

export const destinationToMenuEntry = (
  destinaiton: DestinationPage,
): DestinationMenuEntry => {
  return {
    sanityId: destinaiton.destination.sanityId,
    seoSlug: destinaiton.seoSlug,
    name: destinaiton.destination.name,
    menuDrawing: destinaiton.menuDrawing,
    visibility: destinaiton.visibility,
    firstBookableDate: destinaiton.firstBookableDate,
  };
};
