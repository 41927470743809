export enum CancellationPolicyId {
  STANDARD = "standard",
  FLEXIBLE = "flexible",
}

export const optionalCancellationPolicyIdFromString = (
  cancellationPolicyString: string,
): CancellationPolicyId | undefined => {
  switch (cancellationPolicyString) {
    case CancellationPolicyId.STANDARD:
      return CancellationPolicyId.STANDARD;

    case CancellationPolicyId.FLEXIBLE:
      return CancellationPolicyId.FLEXIBLE;

    default:
      return undefined;
  }
};

/**
 * FIXME: Split into two parts: this one strict that throw error on invalid, and a hook useFlexibilityFromQueryString that handle the default value
 *
 * @deprecated Use zod instead: `z.nativeEnum(CancellationPolicyId).optional().default(CancellationPolicyId.STANDARD)`
 */
export const cancellationPolicyIdFromString = (
  cancellationPolicyString: string,
): CancellationPolicyId => {
  // This does not scale but this is the simple way to reverse map an Enum (key the key from the value)
  switch (cancellationPolicyString) {
    case CancellationPolicyId.STANDARD:
      return CancellationPolicyId.STANDARD;

    case CancellationPolicyId.FLEXIBLE:
      return CancellationPolicyId.FLEXIBLE;

    default:
      return CancellationPolicyId.STANDARD;
    // throw new Error(
    //   `Invalid cancellation policy id "${cancellationPolicyString}"`,
    // );
  }
};
