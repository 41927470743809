import { t } from "@lingui/macro";
import React from "react";
import DestinationButton from "~/applications/Editorials/Ui/Destination/DestinationDrawer/DestinationButton";
import PoitiersMonument from "~/applications/Editorials/Ui/Destination/DestinationDrawer/Drawings/PoitiersMonument.svg";

export const UpcomingDestinations = () => {
  return (
    <>
      <DestinationButton
        isDisabled
        destinationName="Poitiers"
        illustration={PoitiersMonument}
        teasingText={t`Opening 2025`}
      />
    </>
  );
};
