import React from "react";
import IconSvg, { IconSvgProps } from "~/guidelines/Icon/IconSvg";

const Add = ({ color }: IconSvgProps) => {
  return (
    <IconSvg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M18.228 13H13.228V18C13.228 18.55 12.778 19 12.228 19C11.678 19 11.228 18.55 11.228 18V13H6.22803C5.67803 13 5.22803 12.55 5.22803 12C5.22803 11.45 5.67803 11 6.22803 11H11.228V6C11.228 5.45 11.678 5 12.228 5C12.778 5 13.228 5.45 13.228 6V11H18.228C18.778 11 19.228 11.45 19.228 12C19.228 12.55 18.778 13 18.228 13Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};

export default Add;
