import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { DestinationPage } from "~/applications/OfferSearch/Domain/DestinationPage";
import { contentStudioClient } from "~/clients/ContentStudio";
import {
  ALL_RESOURCE_VISIBILITY,
  ResourceVisibility,
  ResourceVisibilityPayloadSchema,
} from "~/core/ResourceVisibility";
import { SanityImageWithMetadataPayloadSchema } from "~/core/api/Sanity/asset";
import {
  SanityLocalizedFieldSchema,
  localizeField,
  localizedFieldsProjection,
} from "~/core/api/Sanity/localize";
import { buildGroqQuery } from "~/core/api/Sanity/query";
import { AVAILABLE_LOCALES } from "~/core/locale";

const getAllDestinationPagesQuery = (locale: AVAILABLE_LOCALES) =>
  buildGroqQuery(`
  *[_type == 'destinationWebsite' && visibility.value in $visibilities && !(_id in path('drafts.**'))] | order(seoSlug.current)
  {
    seoSlug,
    destination->{
      _id,
      name,
      gallery[]{
        asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          }
        },
        caption{ ${localizedFieldsProjection(locale)} }
      },
    },
    firstBookableDate,
    destinationPageEditorial->{
      "heroBackgroundPicture": heroBackgroundPicture.asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
      "pictureGalleryDescription": destinationPictureGalleryDescription{ ${localizedFieldsProjection(
        locale,
      )} },
      "lifeInDestination": {
        "description": lifeInDestinationDescription{ ${localizedFieldsProjection(
          locale,
        )} },
        specificities[]{
          "illlustration": illlustration.asset->{
            url,
            metadata {
              lqip,
              dimensions {
                height,
                width
              },
            },
          },
          title{ ${localizedFieldsProjection(locale)} },
          description{ ${localizedFieldsProjection(locale)} },
        },
      },
      "seo": {
        "picture": opengraphImage.asset->{
          url,
          metadata {
            lqip,
            dimensions {
              height,
              width
            },
          },
        },
        "description": opengraphDescription,
      },
    },
    visibility,
    "menuDrawing": destinationMenuDrawing {
      asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
    },
    photo {
      asset->{
        url,
        metadata {
          lqip,
          dimensions {
            height,
            width
          },
        },
      },
    },
    introduction{ ${localizedFieldsProjection(locale)} },
    "echoppe": (*[
      _type == 'echoppe'
      && destination._ref == ^.destination._ref
      && !(_id in path('drafts.**'))
    ][0]{
      zenChefRestaurantId
    }),
    "numberOfEchoppePages": count(*[
      _type == 'echoppePage'
      && echoppe->destination._ref == ^.destination._ref
      && !(_id in path('drafts.**'))
    ])
  }
`);

const AllDestinationPagesPayloadSchema = z.array(
  z.object({
    seoSlug: z.object({
      current: z.string(),
    }),
    destination: z.object({
      _id: z.string(),
      name: z.string(),
      gallery: z
        .array(
          z.object({
            asset: SanityImageWithMetadataPayloadSchema,
            caption: SanityLocalizedFieldSchema,
          }),
        )
        .nullable()
        .transform((gallery) => gallery ?? []),
    }),
    destinationPageEditorial: z.object({
      heroBackgroundPicture: SanityImageWithMetadataPayloadSchema,
      pictureGalleryDescription: SanityLocalizedFieldSchema,
      lifeInDestination: z.object({
        description: SanityLocalizedFieldSchema,
        specificities: z.array(
          z.object({
            illlustration: SanityImageWithMetadataPayloadSchema,
            title: SanityLocalizedFieldSchema,
            description: SanityLocalizedFieldSchema,
          }),
        ),
      }),
      seo: z.object({
        picture: SanityImageWithMetadataPayloadSchema,
        description: SanityLocalizedFieldSchema,
      }),
    }),
    visibility: ResourceVisibilityPayloadSchema,
    menuDrawing: z.object({
      asset: SanityImageWithMetadataPayloadSchema,
    }),
    photo: z.object({
      asset: SanityImageWithMetadataPayloadSchema,
    }),
    introduction: SanityLocalizedFieldSchema,
    firstBookableDate: z.string().nullable(),
    echoppe: z
      .object({
        zenChefRestaurantId: z.string().nullable(),
      })
      .nullable(),
    numberOfEchoppePages: z.number(),
  }),
);

type FetchDestinationPagesQuery = {
  locale: AVAILABLE_LOCALES;
  visibilities?: Array<ResourceVisibility>;
};

export async function fetchDestinationPages({
  locale,
  visibilities = ALL_RESOURCE_VISIBILITY,
}: FetchDestinationPagesQuery): Promise<Array<DestinationPage>> {
  const response = await contentStudioClient.fetch(
    getAllDestinationPagesQuery(locale),
    { visibilities },
  );

  const allDestinationPagesPayload =
    AllDestinationPagesPayloadSchema.parse(response);

  return allDestinationPagesPayload.map((oneDestinationPagePayload) => {
    let guestCanBookATableAtDestination = false;
    const firstEchoppeOfDestination = oneDestinationPagePayload.echoppe ?? null;
    if (firstEchoppeOfDestination !== null) {
      guestCanBookATableAtDestination =
        firstEchoppeOfDestination.zenChefRestaurantId !== null;
    }

    return {
      seoSlug: oneDestinationPagePayload.seoSlug.current,
      destination: {
        sanityId: oneDestinationPagePayload.destination._id,
        name: oneDestinationPagePayload.destination.name,
        images: oneDestinationPagePayload.destination.gallery.map(
          (oneImage) => ({
            ...oneImage,
            caption: localizeField(oneImage?.caption, locale),
          }),
        ),
      },
      menuDrawing: {
        url: oneDestinationPagePayload.menuDrawing.asset.url,
        metadata: {
          lqip:
            oneDestinationPagePayload.menuDrawing.asset.metadata.lqip ?? null,
          dimensions: {
            width:
              oneDestinationPagePayload.menuDrawing.asset.metadata.dimensions
                .width,
            height:
              oneDestinationPagePayload.menuDrawing.asset.metadata.dimensions
                .height,
          },
        },
      },
      photo: {
        url: oneDestinationPagePayload.photo.asset.url,
        metadata: {
          lqip: oneDestinationPagePayload.photo.asset.metadata.lqip ?? null,
          dimensions: {
            width:
              oneDestinationPagePayload.photo.asset.metadata.dimensions.width,
            height:
              oneDestinationPagePayload.photo.asset.metadata.dimensions.height,
          },
        },
      },
      introduction: localizeField(
        oneDestinationPagePayload.introduction,
        locale,
      ),
      firstBookableDate: oneDestinationPagePayload.firstBookableDate
        ? new Date(oneDestinationPagePayload.firstBookableDate)
        : null,
      destinationPageEditorial: {
        heroBackgroundPicture:
          oneDestinationPagePayload.destinationPageEditorial
            .heroBackgroundPicture,
        pictureGalleryDescription: localizeField(
          oneDestinationPagePayload.destinationPageEditorial
            .pictureGalleryDescription,
          locale,
        ),
        lifeInDestination: {
          description: localizeField(
            oneDestinationPagePayload.destinationPageEditorial.lifeInDestination
              .description,
            locale,
          ),
          specificities:
            oneDestinationPagePayload.destinationPageEditorial.lifeInDestination.specificities.map(
              (oneSpecificity) => ({
                picture: oneSpecificity.illlustration,
                title: localizeField(oneSpecificity.title, locale),
                description: localizeField(oneSpecificity.description, locale),
              }),
            ),
        },
        seo: {
          picture:
            oneDestinationPagePayload.destinationPageEditorial.seo.picture,
          description: localizeField(
            oneDestinationPagePayload.destinationPageEditorial.seo.description,
            locale,
          ),
        },
      },
      visibility: oneDestinationPagePayload.visibility.value,
      zenChefRestaurantId:
        oneDestinationPagePayload.echoppe?.zenChefRestaurantId ?? null,
      features: {
        guestCanBookARoom: oneDestinationPagePayload.firstBookableDate !== null,
        destinationHaveEchoppePage:
          oneDestinationPagePayload.numberOfEchoppePages >= 1,
        guestCanBookATable: guestCanBookATableAtDestination,
      },
    };
  });
}

export const useDestinationPages = (
  query: FetchDestinationPagesQuery,
  initialData?: Array<DestinationPage>,
): { destinationPages: Array<DestinationPage> | null } => {
  const { data: destinationPages = null } = useQuery(
    ["offer-search-destinations-pages"],
    () => fetchDestinationPages(query),
    {
      initialData,
    },
  );

  return { destinationPages };
};
