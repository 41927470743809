import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  formatISO,
  isAfter,
  isSameDay,
  parseISO,
  startOfDay,
} from "date-fns";
import {
  TravelPurpose,
  TravelPurposeSchema,
} from "~/applications/Checkout/Domain/TravelPurpose";

type Guests = {
  readonly nbAdult: number;
  readonly nbChildren: number;
  readonly nbBabies: number;
};

interface StayRequestQueryStringShape {
  checkIn?: string | string[];
  checkOut?: string | string[];
  nbAdult?: string | string[];
  nbChildren?: string | string[];
  nbBabies?: string | string[];
  travelPurpose?: string | string[];
  promoCode?: string | string[];
}

export interface StayRequestShape {
  checkIn: string;
  checkOut: string;
  nbAdult: number;
  nbChildren: number;
  nbBabies: number;
  travelPurpose: TravelPurpose;
  promoCode?: string;
}

export default class StayRequest {
  readonly checkIn: Date;
  readonly checkOut: Date;
  readonly nbAdult: number;
  readonly nbChildren: number;
  readonly nbBabies: number;
  readonly travelPurpose: TravelPurpose;
  readonly promoCode?: string = undefined;

  constructor(
    checkIn: Date,
    checkOut: Date,
    nbAdult: number,
    nbChildren: number,
    nbBabies: number,
    travelPurpose: TravelPurpose = TravelPurpose.LEISURE,
    promoCode: string | undefined = undefined,
  ) {
    const normalizedCheckIn = startOfDay(checkIn);
    const normalizedCheckOut = endOfDay(checkOut);

    if (isSameDay(normalizedCheckIn, normalizedCheckOut)) {
      throw new Error(
        "Invalid stay period: The check in and check out date could not be the same day",
      );
    } else if (isAfter(normalizedCheckIn, normalizedCheckOut)) {
      throw new Error(
        "Invalid stay period: The check in date should be before the check out date",
      );
    }

    this.checkIn = normalizedCheckIn;
    this.checkOut = normalizedCheckOut;

    if (nbAdult < 1) {
      throw new Error(
        "Invalid guest number: A stay request should have at least one adult",
      );
    }

    if (nbChildren < 0) {
      throw new Error(
        "Invalid number of child: A stay request shouldn't have a negative number of child",
      );
    }

    if (nbBabies < 0) {
      throw new Error(
        "Invalid number of baby: A stay request shouldn't have a negative number of baby",
      );
    }

    this.nbAdult = nbAdult;
    this.nbChildren = nbChildren;
    this.nbBabies = nbBabies;

    if (
      travelPurpose !== TravelPurpose.LEISURE &&
      travelPurpose !== TravelPurpose.BUSINESS
    ) {
      throw new Error("Invalid travel purpose");
    }

    this.travelPurpose = travelPurpose;
    this.promoCode = promoCode;

    Object.freeze(this);
  }

  static forTonight(
    today: Date,
    nbAdult: number,
    nbChildren: number,
    nbBabies: number,
  ) {
    const { checkIn: defaultCheckIn, checkOut: defaultCheckOut } =
      this.defaultStay(today);

    return new StayRequest(
      defaultCheckIn,
      defaultCheckOut,
      nbAdult,
      nbChildren,
      nbBabies,
      TravelPurpose.LEISURE,
    );
  }

  static fromQueryString(
    query: StayRequestQueryStringShape,
    today: Date,
  ): StayRequest {
    const {
      checkIn: defaultCheckIn,
      checkOut: defaultCheckOut,
      nbAdult: defaultNbAdult,
      nbChildren: defaultNbChildren,
      nbBabies: defaultNbBabies,
      travelPurpose: defaultTravelPurpose,
      promoCode: defaultPromoCode,
    } = this.defaultStay(today);

    const checkIn =
      query.checkIn && !Array.isArray(query.checkIn)
        ? parseISO(query.checkIn)
        : defaultCheckIn;

    const checkOut =
      query.checkOut && !Array.isArray(query.checkOut)
        ? parseISO(query.checkOut)
        : defaultCheckOut;

    const nbAdult =
      query.nbAdult && !Array.isArray(query.nbAdult)
        ? parseInt(query.nbAdult, 10)
        : defaultNbAdult;

    const nbChildren =
      query.nbChildren && !Array.isArray(query.nbChildren)
        ? parseInt(query.nbChildren, 10)
        : defaultNbChildren;

    const nbBabies =
      query.nbBabies && !Array.isArray(query.nbBabies)
        ? parseInt(query.nbBabies, 10)
        : defaultNbBabies;

    const travelPurpose =
      query.travelPurpose && !Array.isArray(query.travelPurpose)
        ? TravelPurposeSchema.parse(query.travelPurpose)
        : defaultTravelPurpose;

    const promoCode =
      query.promoCode && !Array.isArray(query.promoCode)
        ? query.promoCode
        : defaultPromoCode;

    return new StayRequest(
      checkIn,
      checkOut,
      nbAdult,
      nbChildren,
      nbBabies,
      travelPurpose,
      promoCode,
    );
  }

  static defaultStay(today: Date) {
    const tomorrow = addDays(today, 1);

    return {
      checkIn: today,
      checkOut: tomorrow,
      nbAdult: 1,
      nbChildren: 0,
      nbBabies: 0,
      travelPurpose: TravelPurpose.LEISURE,
      promoCode: undefined,
    };
  }

  isPast(today: Date): boolean {
    return startOfDay(this.checkIn) < startOfDay(today);
  }

  getNbNight(): number {
    return differenceInCalendarDays(this.checkOut, this.checkIn);
  }

  get stayPeriod(): { checkInDate: Date; checkOutDate: Date } {
    return {
      checkInDate: this.checkIn,
      checkOutDate: this.checkOut,
    };
  }

  get guests(): Guests {
    return {
      nbAdult: this.nbAdult,
      nbChildren: this.nbChildren,
      nbBabies: this.nbBabies,
    };
  }

  get nbChild(): number {
    return this.nbChildren + this.nbBabies;
  }

  get nbPayingGuests(): number {
    return this.nbAdult + this.nbChildren;
  }

  get nbGuests(): number {
    return this.nbAdult + this.nbChildren + this.nbBabies;
  }

  toJSON(): StayRequestShape {
    return {
      checkIn: formatISO(this.checkIn, { representation: "date" }),
      checkOut: formatISO(this.checkOut, { representation: "date" }),
      nbAdult: this.nbAdult,
      nbChildren: this.nbChildren,
      nbBabies: this.nbBabies,
      travelPurpose: this.travelPurpose,
      promoCode: this.promoCode,
    };
  }
}
