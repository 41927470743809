import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessageContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(6)};
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const Selector = { Layout, Footer, Actions, ErrorMessageContainer };

export default Selector;
